<template>
  <div
    class="relative grid min-h-screen w-full flex-col items-center gap-10 px-8 lg:grid-cols-2"
  >
    <div class="absolute left-10 top-10 flex items-center gap-x-4">
      <Logo class="h-8 w-fit text-gray-800" />
    </div>

    <NuxtPage />
  </div>
</template>
